import React from "react";

import {
    FormHelperText,
} from '@material-ui/core';
import { fieldRequeried } from '../models/constants';
const SelectInput = (
    name: string,
    label: string,
    onChange: any,
    defaultOption: any,
    value: any,
    error: any,
    options: Array<any>
) => {
    return (
        <div className="form-group">
            <label>{label}:</label>
            <div className="field">
                <select
                    name={name}
                    value={value}
                    onChange={onChange}
                    className="form-control"
                >
                    <option value="-1">{defaultOption}</option>
                    {options.map((option: any) => {
                        return (
                            <option key={option.id} value={option.id}>
                                {option.name}
                            </option>
                        );
                    })}
                </select>
                <FormHelperText error={true}>
                    {error == false ? "" : fieldRequeried}
                </FormHelperText>
            </div>
        </div>
    );
};


export default SelectInput;
