import {
    apiLogin,
    apiSearchConstructionFeatures,
    updateConstructionFeatures
} from '../models/constants'
import axios from 'axios';

export function apiLoginAsync(username: string, password: string, env: string) {
    let payload = {
        user: username,
        password: password,
        env: env
    }
    const data = axios.post(apiLogin(), payload);
    return data;

}

export function apiSearchConstructionFeaturesAsync(isOak: boolean, oak: string, address: string, postalCode: string, env: string) {


    const data = axios.get(apiSearchConstructionFeatures(isOak, oak, address, postalCode, env));
    return data;

}

export function apiUpdateValuationAsync(replacement_cost: any, username: string, id: string, env: string) {

    let payload = {
        replacement_cost: Number(replacement_cost),
        username: username,
        _id: id,
        env: env
    }
    const data = axios.post(updateConstructionFeatures(), payload);
    return data;

}