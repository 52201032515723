import * as React from "react";

import { Route, Switch } from 'react-router-dom';
import ConstructionFeatures from './components/ConstructionFeatures';
import SearchConstructionFeatures from './components/SearchConstructionFeatures'
import Login from './components/Login'
import PageNotFound from './components/PageNotFound';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import {
  constructionFeatureSelected,
  listConstructionFeaturesFiltered,
  constructionFeaturesSearch,
  GlobalContentENV
} from './helper/Context';
import { useState } from "react";
import { SearchConstruction } from './models/models'
function App() {
  const [constructionFeature, setConstructionFeature] = useState({})
  const [listConstructionFeatures, setListConstructionFeatures] = useState(new Array<any>())

  const [searchConstruction, setSearchConstruction] = useState(new SearchConstruction())
  const [env, setEnv] = useState("")
  return (
    <GlobalContentENV.Provider value={{ env, setEnv }}>
      <constructionFeaturesSearch.Provider value={{ searchConstruction, setSearchConstruction }}>
        <constructionFeatureSelected.Provider value={{ constructionFeature, setConstructionFeature }}>
          <listConstructionFeaturesFiltered.Provider value={{ listConstructionFeatures, setListConstructionFeatures }}>
            <div >
              <Switch>
                <Route exact path="/" component={Login} />
                <Route exact path="/ConstructionFeatures" component={ConstructionFeatures} />
                <Route exact path="/SearchConstructionFeatures" component={SearchConstructionFeatures} />
                <Route component={PageNotFound} />
              </Switch>
              <ToastContainer autoClose={5000} hideProgressBar />

            </div>
          </listConstructionFeaturesFiltered.Provider>
        </constructionFeatureSelected.Provider>
      </constructionFeaturesSearch.Provider>
    </GlobalContentENV.Provider>

  );
};

export default App;
