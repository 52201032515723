import { DropDownModel } from '../models/models';
import { listEnv } from '../models/constants';
export function loadConstructionFeatures(json: any) {


    let kitchenCountTypeCode: string = "";
    let yearBuilt: string = "";
    let SquareFootageRange: string = "";
    let FoundationTypeCode: string = "";
    let ArchitecturalStyleTypeCode: string = "";;
    let BuildingStoreyConstruction: string = "";
    let ExteriorWallConstructions: string = "";
    let RoofConstructions: string = "";
    let FinishedBasement: string = "";
    let Garages: string = "";
    let PrimaryHeatingTypeCode: string = "";
    let SwimmingPoolTypeCode: string = "";
    let BathroomConstruction: string = "";

    let valuation: any;
    let costPerSquareFoot: any;
    let idMongo: string = "";

    if (json["Building"]) {


        if (json["Building"].length > 0) {

            if (json['_id']) {
                idMongo = json['_id'];
            }


            if (json["Building"][0]["ResidentialBuilding"]["ResidentialConstructionFeatures"]) {

                if (json["Building"][0]["ResidentialBuilding"]["ResidentialConstructionFeatures"]["YearBuilt"]) {
                    yearBuilt = json["Building"][0]["ResidentialBuilding"]["ResidentialConstructionFeatures"]["YearBuilt"];
                }
                if (json["Building"][0]["ResidentialBuilding"]["ResidentialConstructionFeatures"]["SquareFootage"]) {
                    SquareFootageRange = json["Building"][0]["ResidentialBuilding"]["ResidentialConstructionFeatures"]["SquareFootage"];
                }

                if (json["Building"][0]["ResidentialBuilding"]["ResidentialConstructionFeatures"]["FoundationTypeCode"]) {
                    FoundationTypeCode = json["Building"][0]["ResidentialBuilding"]["ResidentialConstructionFeatures"]["FoundationTypeCode"];
                }

                if (json["Building"][0]["ResidentialBuilding"]["ResidentialConstructionFeatures"]["ArchitecturalStyleTypeCode"]) {
                    ArchitecturalStyleTypeCode = json["Building"][0]["ResidentialBuilding"]["ResidentialConstructionFeatures"]["ArchitecturalStyleTypeCode"]
                }


                if (json["Building"][0]["ResidentialBuilding"]["ResidentialConstructionFeatures"]["BathroomConstruction"]) {

                    BathroomConstruction = json["Building"][0]["ResidentialBuilding"]["ResidentialConstructionFeatures"]["BathroomConstruction"]["BathroomCountTypeCode"]
                }

                if (json["Building"][0]["ResidentialBuilding"]["ResidentialConstructionFeatures"]["KitchenConstruction"]['KitchenCountTypeCode']) {
                    kitchenCountTypeCode = json["Building"][0]["ResidentialBuilding"]["ResidentialConstructionFeatures"]["KitchenConstruction"]['KitchenCountTypeCode']

                }
                if (json["Building"][0]["ResidentialBuilding"]["ResidentialConstructionFeatures"]["BuildingStoreyConstruction"]["StoreyCountTypeCode"]) {

                    BuildingStoreyConstruction = json["Building"][0]["ResidentialBuilding"]["ResidentialConstructionFeatures"]["BuildingStoreyConstruction"]["StoreyCountTypeCode"]
                }

                if (json["Building"][0]["ResidentialBuilding"]["ResidentialConstructionFeatures"]["ExteriorWallConstructions"]) {

                    ExteriorWallConstructions = json["Building"][0]["ResidentialBuilding"]["ResidentialConstructionFeatures"]
                    ["ExteriorWallConstructions"][0]["ExteriorWallTypeCode"]
                }

                if (json["Building"][0]["ResidentialBuilding"]["ResidentialConstructionFeatures"]["RoofConstructions"]) {

                    RoofConstructions = json["Building"][0]["ResidentialBuilding"]["ResidentialConstructionFeatures"]
                    ["RoofConstructions"][0]["RoofTypeCode"]
                }

                FinishedBasement = json["Building"][0]["ResidentialBuilding"]["ResidentialConstructionFeatures"]["FinishedBasement"] ? "Yes" : "No";

                if (json["Building"][0]["ResidentialBuilding"]["ResidentialConstructionFeatures"]["Garages"]) {

                    Garages = json["Building"][0]["ResidentialBuilding"]["ResidentialConstructionFeatures"]
                    ["Garages"][0]["GarageTypeCode"];
                }

                if (json["Building"][0]["ResidentialBuilding"]["ResidentialConstructionFeatures"]["PrimaryHeatingTypeCode"]) {

                    PrimaryHeatingTypeCode = json["Building"][0]["ResidentialBuilding"]["ResidentialConstructionFeatures"]["PrimaryHeatingTypeCode"]
                }

                if (json["Building"][0]["ResidentialBuilding"]["ResidentialConstructionFeatures"]["SwimmingPoolTypeCode"]) {

                    SwimmingPoolTypeCode = json["Building"][0]["ResidentialBuilding"]["ResidentialConstructionFeatures"]["SwimmingPoolTypeCode"]
                }


                if (json["Building"][0]["Valuation"]) {
                    valuation = json["Building"][0]["Valuation"]["ReplacementCost"];
                }

                if (json["Building"][0]["Valuation"]["CostPerSquareFoot"]) {
                    costPerSquareFoot = json["Building"][0]["Valuation"]["CostPerSquareFoot"];
                }

            }
        }
    }


    return {
        yearBuilt: yearBuilt,
        SquareFootageRange: SquareFootageRange,
        FoundationTypeCode: FoundationTypeCode,
        ArchitecturalStyleTypeCode: ArchitecturalStyleTypeCode,
        BathroomConstruction: BathroomConstruction,
        kitchenCountTypeCode: kitchenCountTypeCode,
        BuildingStoreyConstruction: BuildingStoreyConstruction,
        ExteriorWallConstructions: ExteriorWallConstructions,
        RoofConstructions: RoofConstructions,
        FinishedBasement: FinishedBasement,
        Garages: Garages,
        PrimaryHeatingTypeCode: PrimaryHeatingTypeCode,
        SwimmingPoolTypeCode: SwimmingPoolTypeCode,
        valuation: valuation,
        costPerSquareFoot: costPerSquareFoot,
        idMongo: idMongo

    }
}

export function findEnvDescription(id: number) {
    const env: DropDownModel = listEnv.find(temp => temp.id === id) || new DropDownModel();
    return env.name;
}
