import React, { useState, useContext } from 'react';
import { deleteCache } from '../cache/cache';
import { userLoginKey } from '../cache/constants';
import { Redirect } from 'react-router-dom';

import {
    constructionFeaturesSearch,
    constructionFeatureSelected,
    listConstructionFeaturesFiltered
} from '../helper/Context';
import { SearchConstruction } from '../models/models'
function Header(user: string, isSearchPage: boolean) {

    const [logOut, setLogOut] = useState(false)
    const [search, setSearch] = useState(false)

    const { setSearchConstruction } = useContext(constructionFeaturesSearch)
    const { setConstructionFeature } = useContext(constructionFeatureSelected)
    const { setListConstructionFeatures } = useContext(listConstructionFeaturesFiltered)

    const cleanCache = (event: any) => {
        event.preventDefault();
        setSearchConstruction(new SearchConstruction())
        setConstructionFeature({})
        setListConstructionFeatures([])

        deleteCache(userLoginKey);
        setLogOut(true);
    }

    return (
        <>
            {logOut ? <Redirect to="/" /> : <></>}
            {search ? <Redirect to="/SearchConstructionFeatures" /> : <></>}
            <div className="header">
                <div className="inner_header">
                    <div className="logo">
                        <h1><span>Residential Construction Features</span></h1>
                    </div>
                    <ul className="navigation">
                        {isSearchPage ? <></> : <a ><li onClick={e => setSearch(true)}>Search Features</li></a>}
                        <a><li onClick={cleanCache}>Log out:{user}</li></a>

                    </ul>
                </div>
            </div>
        </>



    )
}
export default Header;