import { createContext } from 'react';
import { SearchConstruction } from '../models/models';


export type GlobalContentConstructionFeatureSelected = {
    constructionFeature: {}
    setConstructionFeature: (constructionFeature: {}) => void
}

export type GlobalContentListConstructionFeaturesFiltered = {
    listConstructionFeatures: Array<any>
    setListConstructionFeatures: (listConstructionFeatures: []) => void
}
export type GlobalContentSearchConstruction = {
    searchConstruction: SearchConstruction
    setSearchConstruction: (searchConstruction: SearchConstruction) => void
}


export const GlobalContentENV = createContext<contentENV>({
    env: "",
    setEnv: () => { }
})


export type contentENV = {
    env: string
    setEnv: (env: string) => void
}


export const constructionFeaturesSearch = createContext<GlobalContentSearchConstruction>({
    searchConstruction: new SearchConstruction(),
    setSearchConstruction: () => { },
})

export const constructionFeatureSelected = createContext<GlobalContentConstructionFeatureSelected>({
    constructionFeature: {},
    setConstructionFeature: () => { },
})

export const listConstructionFeaturesFiltered = createContext<GlobalContentListConstructionFeaturesFiltered>({
    listConstructionFeatures: new Array<any>(),
    setListConstructionFeatures: () => { }


})
