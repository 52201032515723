export function CheckFormValid(
    valuation: string,
    idMongo: string) {

    return (
        valuation.length === 0 || idMongo.length === 0

    ) ? true : false;



}