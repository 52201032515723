import React, { useState, useContext } from "react";
import { Redirect } from 'react-router-dom';
import { apiLoginAsync } from '../actions/actionsAxios';
import { toast } from 'react-toastify';
import { loginFailed, loginSucessful, listEnv } from '../models/constants';
import { saveInCache } from '../cache/cache'
import { emailLoginKey, userLoginKey } from '../cache/constants'
import DropDown from '../components/DropDown';
import { DropDownModel } from '../models/models';
import { findEnvDescription } from '../actions/actions';
import { GlobalContentENV } from '../helper/Context'
const RenderForm: React.FC<{}> = () => {
  const [password, setPassword] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const { env, setEnv } = useContext(GlobalContentENV)
  const [localEnv, setLocalEnv] = useState({ id: 3, name: "PROD" })
  const [redirectProperty, setRedirect] = useState(false)
  const styles = {
    marginTop: "5%",
    border: '1px solid rgba(0, 0, 0, 0.05)',
    maxWidth: "600px"

  };

  const login = async () => {

    await apiLoginAsync(username, password, localEnv.name.toLowerCase()).then(response => {
      if (response.status === 200) {
        setEnv(localEnv.name.toLowerCase())
        saveInCache(response.data.name, userLoginKey);
        saveInCache(username, emailLoginKey);
        toast.success(loginSucessful);
        setRedirect(true);
      } else {
        toast.error(response.data.body.error);
      }
    }).catch(error => {
      toast.error(loginFailed);
    });

  }
  const onChangeEnv = (event: any) => {
    const { value } = event.target;
    const env: DropDownModel = new DropDownModel();
    env.id = value;
    env.name = findEnvDescription(Number(value))
    setLocalEnv(env);
  }

  return (


    <div className="container" style={styles}>


      <h3 style={{ textAlign: "center" }}>Login</h3>

      <div className="form-group">
        {DropDown("Environment",
          "Environment",
          onChangeEnv,
          "Select Environment",
          localEnv.id,
          false,
          listEnv)}
      </div>


      <div className="form-group">
        <label>Username</label>
        <input type="text" onChange={e => setUsername((e.target.value))} className="form-control" placeholder="Enter username" />
      </div>

      <div className="form-group">
        <label>Password</label>
        <input type="password" onChange={e => setPassword((e.target.value))} className="form-control" placeholder="Enter password" />
      </div>

      <button type="button" disabled={username.length === 0 || password.length === 0 || localEnv.id <= 0}
        onClick={login} className="btn btn-primary btn-block">Login</button>

      {redirectProperty ? <Redirect to="/SearchConstructionFeatures" /> : <></>}

    </div>

  );
};

export default RenderForm;
