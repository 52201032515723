import { CreateKeyCache, expiryDay } from "./constants";

export function getCache(key: string) {
    try {

        checkExpiryDay(key);

        const serializedState = localStorage.getItem(key);

        if (serializedState === null) return undefined;

        return JSON.parse(serializedState)

    } catch (e) {

        return undefined
    }
}

function checkExpiryDay(key: string) {


    const dateSave = localStorage.getItem(CreateKeyCache(key));

    if (dateSave === null) return;

    var dateOfSave = JSON.parse(dateSave)

    const currentTime = new Date().getTime();

    const prevAcceptedExpired = currentTime - dateOfSave > expiryDay;

    if (prevAcceptedExpired) {
        localStorage.removeItem(key);
        localStorage.removeItem(CreateKeyCache(key));
    }
}

export function deleteCache(key: string) {
    localStorage.removeItem(key);
    localStorage.removeItem(CreateKeyCache(key));

}


export function saveInCache(state: any, key: any) {
    try {

        const serializedState = JSON.stringify(state);
        localStorage.setItem(key, serializedState)

        const dateSave = JSON.stringify(new Date().getTime());
        localStorage.setItem(CreateKeyCache(key), dateSave)


    } catch (e) {
        console.log(e)
    }
}