import React from 'react';
import {
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,

} from '@material-ui/core';
import JSONTree from 'react-json-tree';


const DialogComponent = (isOpen: boolean, onCloseDialog: any, data: any, title: string) => {
    return (
        <>

            <Dialog

                open={isOpen}

            >

                <DialogTitle>
                    <div className="row">
                        <div className="col-md-12 col-lg-12">
                            <h3>{title}</h3>
                        </div>
                    </div>
                </DialogTitle>

                <DialogContent style={{ minWidth: "500px", minHeight: "100px" }}>

                    <JSONTree data={data} />
                </DialogContent>

                <DialogActions>
                    <button onClick={onCloseDialog} color="primary" className="btn btn-previous btn-fill btn-primary btn-wd">
                        Close
                    </button>
                </DialogActions>
            </Dialog>
        </>
    )
};
export default DialogComponent;