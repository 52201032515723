import * as React from 'react';
import { Tooltip } from '@material-ui/core';

import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';

const IconButtonComponent  =(title:string,name:string,onClick:any,styles:any,disabled:boolean,image:any,color:any) => {

    return (
        <>
            <Tooltip title={title} aria-label={name}>
                <IconButton
                    onClick={onClick}
                    aria-label={name}
                    style={styles}
                    disabled={disabled}>
                    <Icon className={classNames(image)} color={color} />
                </IconButton>
            </Tooltip>
        </>
    )

}
export default IconButtonComponent;

  


      
    
