import React, { useState, useEffect, useContext } from 'react';
import {
    fieldRequeried,
    internalError,
    recordUpdated
} from '../models/constants'
import {
    FormHelperText,
    TextField
} from '@material-ui/core';
import NumberFormat from "react-number-format";
import { CheckFormValid } from '../validations/validations';
import { toast } from 'react-toastify';
import Header from '../components/Header';
import { userLoginKey, emailLoginKey } from '../cache/constants';
import { getCache } from '../cache/cache';
import { Redirect } from 'react-router-dom';
import { apiUpdateValuationAsync } from '../actions/actionsAxios';
import { loadConstructionFeatures } from '../actions/actions';
import {
    constructionFeatureSelected,
    GlobalContentENV
} from '../helper/Context';



const ConstructionFeatures = (
) => {

    const [logOut, setLogOut] = useState(false)
    const [user] = useState(getCache(userLoginKey))
    const [email] = useState(getCache(emailLoginKey))
    const [yearBuilt, setYearBuilt] = useState("")
    const [squareFeet, setSquareFeet] = useState("")
    const [foundationType, setFoundationType] = useState("")
    const [architecturalStyle, setArchitecturalStyleId] = useState("")
    const [Storeys, setStoreys] = useState("")
    const [kitchens, setKitchens] = useState("")
    const [bathrooms, setBathrooms] = useState("")

    const [exteriorWallType, setExteriorWallType] = useState("")
    const [roofType, setRoofType] = useState("")
    const [finishedBasement, setFinishedBasement] = useState("")
    const [garageType, setGarageTypeId] = useState("")
    const [primaryHeatingType, setPrimaryHeatingTypeId] = useState("")
    const [swimmingPoolType, setSwimmingPoolType] = useState("")
    const { constructionFeature } = useContext(constructionFeatureSelected)


    const [valuation, setValuation] = useState("")
    const [newValuation, setNewValuation] = useState("")
    const [idMongo, setIdMongo] = useState("")
    const [update, setUpdate] = useState(false)
    const [costPerSqft, setCostPerSqft] = useState("")
    const { env } = useContext(GlobalContentENV)


    useEffect(() => {
        if (user === undefined || user === "") {
            setLogOut(true);
        }
        setConstructionFeaturesValues(constructionFeature);
    }, []);


    const setConstructionFeaturesValues = (constructionFeature: any) => {
        const result = loadConstructionFeatures(constructionFeature);
        setYearBuilt(result.yearBuilt);
        setSquareFeet(result.SquareFootageRange);
        setFoundationType(result.FoundationTypeCode);
        setArchitecturalStyleId(result.ArchitecturalStyleTypeCode);
        setBathrooms(result.BathroomConstruction);
        setKitchens(result.kitchenCountTypeCode);
        setStoreys(result.BuildingStoreyConstruction);
        setExteriorWallType(result.ExteriorWallConstructions);
        setRoofType(result.RoofConstructions);
        setFinishedBasement(result.FinishedBasement);
        setGarageTypeId(result.Garages);
        setPrimaryHeatingTypeId(result.PrimaryHeatingTypeCode);
        setSwimmingPoolType(result.SwimmingPoolTypeCode);
        setValuation(result.valuation);
        setCostPerSqft(result.costPerSquareFoot);
        setIdMongo(result.idMongo);

    }


    const updateInformation = async (event: any) => {

        event.preventDefault();
        setUpdate(true)
        await apiUpdateValuationAsync(newValuation, email, idMongo, env).then(response => {
            setUpdate(false)
            if (response.status === 200) {
                toast.success(recordUpdated);
                setConstructionFeaturesValues(response.data);
            } else {
                toast.error(internalError);
            }
        }).catch(error => {
            toast.error(internalError);
            setUpdate(false)
        });

    }

    return (

        <div className="container-fluid" >

            {logOut ? <Redirect to="/" /> : <></>}

            <div className="row">
                <div className="col-md-12">
                    {Header(user, false)}
                </div>

            </div>


            <div className="row offset-md-4" style={{ marginTop: "20px" }}>
                <div className="col-md-4">
                    <label style={{ fontWeight: "bold", display: "inline" }}>Year Built: </label>
                    <label style={{ display: "inline" }}>{yearBuilt}</label>
                </div>
                <div className="col-md-4">
                    <label style={{ fontWeight: "bold", display: "inline" }}>Square Feet: </label>
                    <label style={{ display: "inline" }}>{squareFeet}</label>
                </div>

            </div>


            <div className="row offset-md-4" style={{ marginTop: "15px" }}>
                <div className="col-md-4">

                    <label style={{ fontWeight: "bold", display: "inline" }}>Foundation Type: </label>
                    <label style={{ display: "inline" }}>{foundationType}</label>
                </div>
                <div className="col-md-4">

                    <label style={{ fontWeight: "bold", display: "inline" }}>Architectural Style: </label>
                    <label style={{ display: "inline" }}>{architecturalStyle}</label>
                </div>

            </div>


            <div className="row offset-md-4" style={{ marginTop: "15px" }}>
                <div className="col-md-4">

                    <label style={{ fontWeight: "bold", display: "inline" }}>Bathrooms: </label>
                    <label style={{ display: "inline" }}>{bathrooms}</label>
                </div>

                <div className="col-md-4" >

                    <label style={{ fontWeight: "bold", display: "inline" }}>Kitchens: </label>
                    <label style={{ display: "inline" }}>{kitchens}</label>
                </div>


            </div>

            <div className="row  offset-md-4" style={{ marginTop: "15px" }}>
                <div className="col-md-4">
                    <label style={{ fontWeight: "bold", display: "inline" }}>Building Storeys: </label>
                    <label style={{ display: "inline" }}>{Storeys}</label>
                </div>
                <div className="col-md-4">
                    <label style={{ fontWeight: "bold", display: "inline" }}>Exterior Wall Type: </label>
                    <label style={{ display: "inline" }}>{exteriorWallType}</label>
                </div>


            </div>


            <div className="row offset-md-4" style={{ marginTop: "15px" }}>
                <div className="col-md-4">

                    <label style={{ fontWeight: "bold", display: "inline" }}>Roof Type: </label>
                    <label style={{ display: "inline" }}>{roofType}</label>

                </div>
                <div className="col-md-4">
                    <label style={{ fontWeight: "bold", display: "inline" }}>Finished Basement: </label>
                    <label style={{ display: "inline" }}>{finishedBasement}</label>
                </div>


            </div>

            <div className="row offset-md-4" style={{ marginTop: "15px" }}>
                <div className="col-md-4">

                    <label style={{ fontWeight: "bold", display: "inline" }}>Garage Type: </label>
                    <label style={{ display: "inline" }}>{garageType}</label>
                </div>
                <div className="col-md-4">
                    <label style={{ fontWeight: "bold", display: "inline" }}>Primary Heating Type: </label>
                    <label style={{ display: "inline" }}>{primaryHeatingType}</label>

                </div>

            </div>


            <div className="row offset-md-4" style={{ marginTop: "15px" }}>
                <div className="col-md-4">
                    <label style={{ fontWeight: "bold", display: "inline" }}>Swimming Pool Type </label>
                    <label style={{ display: "inline" }}>{swimmingPoolType}</label>
                </div>

            </div>


            <div className="row offset-md-4" style={{ marginTop: "15px" }}>


                <div className="col-md-4">
                    <label style={{ fontWeight: "bold", display: "inline" }}>Valuation: </label>
                    <label style={{ display: "inline" }}>{valuation}</label>
                </div>

                <div className="col-md-4">
                    <label style={{ fontWeight: "bold", display: "inline" }}>Cost per Sqf: </label>
                    <label style={{ display: "inline" }}>{costPerSqft}</label>
                </div>
            </div>

            <div className="row offset-md-4" style={{ marginTop: "15px" }}>
                <div className="col-md-4">
                    <label style={{ fontWeight: "bold", display: "inline" }}>New valuation:</label>
                    <NumberFormat
                        style={{ width: "100%" }}
                        name="valuation"
                        customInput={TextField}
                        allowNegative={true}
                        defaultValue={2}

                        decimalSeparator={"."}
                        value={newValuation}
                        onChange={e => setNewValuation(e.target.value)}
                    />
                    <FormHelperText error={true}>
                        {newValuation.length === 0 ? fieldRequeried : ""}
                    </FormHelperText>

                </div>
            </div>



            <div className="row offset-md-4">
                <div className="col-md-2" style={{ marginTop: "15px" }}>
                    <button type="button" onClick={updateInformation} disabled={CheckFormValid(newValuation, idMongo) || update} className="btn btn-primary">
                        {update ? "Updating..." : "Update information"}
                    </button>

                </div>




            </div>
        </div>
    );
};


export default ConstructionFeatures;
