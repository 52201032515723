import React, { useState, useEffect, useContext } from 'react';
import { getCache } from '../cache/cache';
import { userLoginKey } from '../cache/constants';
import { Redirect } from 'react-router-dom';
import Header from '../components/Header';
import {
    InputLabel,
    TextField
} from '@material-ui/core';
import { apiSearchConstructionFeaturesAsync, } from '../actions/actionsAxios';
import { toast } from 'react-toastify';
import {
    internalError,
    notRecordFound,
} from '../models/constants'
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import IconButtonComponent from './IconButtonComponent';
import DialogComponent from './Dialog';
import '../css/styles.css';
import {
    constructionFeatureSelected,
    listConstructionFeaturesFiltered,
    constructionFeaturesSearch,
    GlobalContentENV
} from '../helper/Context';
import { SearchConstruction } from '../models/models'
const SearchConstructionFeatures = (
) => {

    const [logOut, setLogOut] = useState(false)
    const [user] = useState(getCache(userLoginKey))
    const [oak, setOAK] = useState("")
    const [postalCode, setPostalCode] = useState("")
    const [address, setAddress] = useState("")
    const [search, setSearch] = useState(false)
    const [openDialog, setOpenDialog] = useState(false);
    const [title, setTitle] = useState("")
    const [objModal, setModal] = useState("")
    const { setConstructionFeature } = useContext(constructionFeatureSelected)

    const { listConstructionFeatures, setListConstructionFeatures } = useContext(listConstructionFeaturesFiltered)
    const { env } = useContext(GlobalContentENV)
    const { searchConstruction, setSearchConstruction } = useContext(constructionFeaturesSearch)
    const [redirectToEditPage, setRedirectToEditPage] = useState(false)
    useEffect(() => {
        if (user === undefined || user === "") {
            setLogOut(true);
        }
        if (searchConstruction.oak.length > 0 || (searchConstruction.address.length > 0 && searchConstruction.postalCode.length > 0)) {
            searchInformationWithParams();
        }




    }, []);

    const searchInformationWithParams = async () => {


        const isOak: boolean = searchConstruction.oak.length > 0 ? true : false;
        setSearch(true);
        await apiSearchConstructionFeaturesAsync(isOak, searchConstruction.oak,
            searchConstruction.address,
            searchConstruction.postalCode,
            env).then(response => {
                setSearch(false);
                if (response.status === 200) {
                    if (response.data.length > 0) {
                        setListConstructionFeatures(response.data);
                        setOAK(searchConstruction.oak)
                        setAddress(searchConstruction.address);
                        setPostalCode(searchConstruction.postalCode);

                    } else {
                        toast.error(notRecordFound);
                    }
                } else {
                    toast.error(internalError);
                }
            }).catch(error => {
                toast.error(internalError);
                setSearch(false);
            });

    }




    const searchInformation = async (event: any) => {

        event.preventDefault();
        const isOak: boolean = oak.length > 0 ? true : false;
        setSearch(true);
        await apiSearchConstructionFeaturesAsync(isOak, oak, address, postalCode, env).then(response => {
            setSearch(false);
            if (response.status === 200) {
                if (response.data.length > 0) {
                    setListConstructionFeatures(response.data);
                    const searchConstruction: SearchConstruction = {
                        oak: oak,
                        postalCode: postalCode,
                        address: address
                    };
                    setSearchConstruction(searchConstruction);

                } else {
                    toast.error(notRecordFound);
                }
            } else {
                toast.error(internalError);
            }
        }).catch(error => {
            toast.error(internalError);
            setSearch(false);
        });

    }

    const showAddress = (event: any) => {
        setTitle('Address');
        setModal(event.Addresses);
        setOpenDialog(true);

    }
    const onCloseDialog = () => {
        setOpenDialog(false);
    }
    const editConstructionFeature = (event: any) => {

        setConstructionFeature(event);
        setRedirectToEditPage(true);

    }


    const cellSearch = (cell: any, row: any, rowIndex: any, formatExtraData: any) => {

        return (
            <>

                {IconButtonComponent("Address",
                    "Address",
                    () => showAddress(row),
                    { width: '50px', backgroundColor: 'transparent', color: "gray" },
                    false,
                    "fas fa-search",
                    "inherit")}
            </>

        );
    }

    const cellEdit = (cell: any, row: any, rowIndex: any, formatExtraData: any) => {

        return (
            <>

                {IconButtonComponent("Edit",
                    "Edit",
                    () => editConstructionFeature(row),
                    { width: '50px', backgroundColor: 'transparent', color: "gray" },
                    false,
                    "fas fa-pen-square",
                    "inherit")}
            </>

        );
    }



    const columns = [

        {
            dataField: '_id',
            text: 'Edit',
            formatter: cellEdit,
        },
        {
            dataField: 'Addresses[0]',
            text: 'Address',
            formatter: cellSearch,
        },
        {
            dataField: 'Building[0].Valuation.ReplacementCost',
            text: 'Valuation',
            csvType: Number

        },
        {
            dataField: 'Building[0].Valuation.CostPerSquareFoot',
            text: 'Cost Per sqf',
            csvType: Number
        },

        {
            dataField: 'Building[0].ResidentialBuilding.ResidentialConstructionFeatures.YearBuilt',
            text: 'Year Built',
            csvType: String
        }, {
            dataField: 'Building[0].ResidentialBuilding.ResidentialConstructionFeatures.SquareFootage',
            text: 'Square Feet',
            csvType: Number

        }, {
            dataField: 'Building[0].ResidentialBuilding.ResidentialConstructionFeatures.FoundationTypeCode',
            text: 'Foundation Type',
            csvType: Number

        },
        {
            dataField: 'Building[0].ResidentialBuilding.ResidentialConstructionFeatures.ArchitecturalStyleTypeCode',
            text: 'Architectural Style',
            csvType: Number

        },
        {
            dataField: 'Building[0].ResidentialBuilding.ResidentialConstructionFeatures.BathroomConstruction.BathroomCountTypeCode',
            text: 'Bathrooms',
            csvType: Number

        },
        {
            dataField: 'Building[0].ResidentialBuilding.ResidentialConstructionFeatures.KitchenConstruction.KitchenCountTypeCode',
            text: 'Kitchens',
            csvType: Number

        },
        {
            dataField: 'Building[0].ResidentialBuilding.ResidentialConstructionFeatures.BuildingStoreyConstruction.StoreyCountTypeCode',
            text: 'Building Storeys',
            csvType: Number

        },
        {
            dataField: 'Building[0].ResidentialBuilding.ResidentialConstructionFeatures.ExteriorWallConstructions[0].ExteriorWallTypeCode',
            text: 'Exterior Wall Type',
            csvType: String

        },
        {
            dataField: 'Building[0].ResidentialBuilding.ResidentialConstructionFeatures.RoofConstructions[0].RoofTypeCode',
            text: 'Roof Type',
            csvType: String

        },
        {
            dataField: 'Building[0].ResidentialBuilding.ResidentialConstructionFeatures.FinishedBasement',
            text: 'Finished Basement',
            csvType: Number

        },
        {
            dataField: 'Building[0].ResidentialBuilding.ResidentialConstructionFeatures.Garages[0].GarageTypeCode',
            text: 'Garage Type',
            csvType: String

        },
        {
            dataField: 'Building[0].ResidentialBuilding.ResidentialConstructionFeatures.PrimaryHeatingTypeCode',
            text: 'Primary Heating Type',
            csvType: String

        },
        {
            dataField: 'Building[0].ResidentialBuilding.ResidentialConstructionFeatures.SwimmingPoolTypeCode',
            text: 'Swimming Pool Type',
            csvType: String
        },


    ];

    return (

        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12">
                    {Header(user, true)}
                </div>

            </div>


            <div className="row offset-md-2" style={{ marginTop: "25px" }}>
                <div className="col-md-3">
                    <label style={{ fontWeight: "bold", display: "inline" }}>Oak:</label>
                    <InputLabel style={{ display: "inline" }} error={true}>{(oak.length === 0 && address.length === 0) ? "*" : ""}</InputLabel>
                    <TextField
                        name="OAK"
                        fullWidth
                        onChange={e => setOAK((e.target.value))}
                        value={oak}
                        multiline
                        disabled={address.length > 0 ? true : false}
                        inputProps={{ rows: 1 }}
                    />
                </div>

                <div className="col-md-3">

                    <label style={{ fontWeight: "bold", display: "inline" }}>Address:</label>
                    <InputLabel style={{ display: "inline" }} error={true}>{(oak.length === 0 && address.length === 0) ? "*" : ""}</InputLabel>


                    <TextField
                        name="address"
                        fullWidth
                        onChange={e => setAddress((e.target.value))}
                        value={address}
                        multiline
                        disabled={oak.length > 0 ? true : false}
                        inputProps={{ rows: 1 }}
                    />
                </div>

                <div className="col-md-3">

                    <label style={{ fontWeight: "bold", display: "inline" }}>Postal Code:</label>
                    <InputLabel style={{ display: "inline" }} error={true}>{(oak.length === 0 && address.length === 0) ? "*" : ""}</InputLabel>


                    <TextField
                        name="address"
                        fullWidth
                        onChange={e => setPostalCode((e.target.value))}
                        value={postalCode}
                        multiline
                        disabled={oak.length > 0 ? true : false}
                        inputProps={{ rows: 1 }}
                    />
                </div>

                <div className="col-md-1" style={{ marginTop: "20px" }}>

                    <button onClick={searchInformation} type="button" disabled={(oak.length === 0 && (address.length === 0 || postalCode.length === 0))} className="btn btn-primary">
                        {search ? "Searching..." : "Search"}
                    </button>

                </div>

            </div>

            <div className="row" style={{ marginTop: "25px" }}>
                <div className="col-md-12" >
                    <ToolkitProvider
                        keyField="id"
                        data={listConstructionFeatures}
                        columns={columns}

                    >
                        {
                            props => (
                                <div>

                                    <BootstrapTable wrapperClasses="table-responsive"  {...props.baseProps}

                                        id="_id"
                                        pagination={paginationFactory({
                                            sizePerPageList: [20, 30, 50],
                                            showTotal: true,

                                        })
                                        } />
                                </div>

                            )
                        }
                    </ToolkitProvider>
                </div>

            </div>


            <div className="row">
                <div className="col-md-12">
                    {DialogComponent(openDialog, onCloseDialog, objModal, title)}
                </div>

            </div>
            {redirectToEditPage ? <Redirect to="/ConstructionFeatures" /> : <></>}
            {logOut ? <Redirect to="/" /> : <></>}

        </div>
    )


}

export default SearchConstructionFeatures;
