//axios
import { DropDownModel } from './models'
export function apiLogin() {
    return baseUrl + `/auth-user`;

}
export function apiSearchConstructionFeatures(isOak: boolean, oak: string, street_address: string, postalCode: string, env: string) {
    if (isOak) {


        return baseUrl + `/document/?oak=${oak}&env=${env}`;
    } else {
        return baseUrl + `/document/?street_address=${street_address}&postal_code=${postalCode}&env=${env}`;
    }
}

export function updateConstructionFeatures() {
    return baseUrl + `/document`;

}

//message

export const baseUrl = process.env.REACT_APP_STAGE?.toLocaleLowerCase() === 'dev' ? 'https://api-nginx-ingress.devmsenv-k8s.optaservice.com/devmsenv-api-gw/dev/static-cache' : 'https://api-ingress.sharedsvcs.optaservice.com/ss-apigw-prod/prod/static-cache';

export const loginSucessful: string = 'Login successful';
export const loginFailed: string = "Login failed.";
export const internalError: string = "Internal error.";
export const notRecordFound: string = "Not record found.";
export const recordUpdated: string = "Record updated."
export const fieldRequeried: string = "Field required";

export const listEnv: DropDownModel[] = [
    {
        "id": 1,
        "name": "TEST"
    },
    {
        "id": 2,
        "name": "CT"
    },
    {
        "id": 3,
        "name": "PROD"
    }
]
